.btn:has(svg) {
  justify-content: center;
  align-items: center;
  display: inline-flex;
}

.modal {
  scrollbar-gutter: stable both-edges;
}

._78yjJW_RangeSlider {
  width: 100%;
  height: 20px;
  margin: 1rem 0;
  position: relative;
  overflow: hidden;

  & input[type="range"] {
    appearance: none;
    cursor: pointer;
    background: none;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
  }

  & input[type="range"]:focus {
    outline: none;
  }

  & input[type="range"]::-webkit-slider-runnable-track {
    height: 0;
  }

  & input[type="range"]::-webkit-slider-thumb {
    appearance: none;
    background-color: var(--bs-green);
    z-index: 2;
    pointer-events: all;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    margin-top: -10px;
    position: relative;
  }

  & input[type="range"]::-moz-range-track {
    height: 0;
  }

  & input[type="range"]::-moz-range-thumb {
    background-color: var(--bs-green);
    z-index: 2;
    pointer-events: all;
    border: none;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    margin-top: -10px;
    position: relative;
  }
}

._78yjJW_RangeSlider__trackBackground, ._78yjJW_RangeSlider__track {
  pointer-events: none;
  height: 6px;
  position: absolute;
  top: 7px;
  bottom: 7px;
}

._78yjJW_RangeSlider__track {
  background-color: var(--bs-green);
  z-index: 1;
}

._78yjJW_RangeSlider__trackBackground {
  z-index: 0;
  background-color: #eee;
}

.xg-g6W_FilteredProducts__filter {
  flex-wrap: wrap;
  gap: .25rem;
  margin: .5rem 0 0;
  display: flex;
}

.xg-g6W_FilteredProducts__productsGrid {
  grid-template-columns: 1fr;
  gap: 1.5rem;
  display: grid;
}

.xg-g6W_FilteredProducts__productsGrid:empty {
  grid-template-columns: 1fr;
}

.xg-g6W_FilteredProducts__productsGrid:empty:before {
  content: "За пошуковими крітеріями нічого не знайдено.";
  text-align: center;
  opacity: .75;
  padding: 5rem;
  font-style: italic;
  display: block;
}

@media (width >= 768px) {
  .xg-g6W_FilteredProducts__productsGrid {
    grid-template-columns: 1fr 1fr;
  }
}

.xg-g6W_productCard {
  opacity: 1;
  transition: transform .25s ease-in-out, opacity .25s ease-in-out;
  transform: translateY(0);
}

@media (width >= 1024px) {
  .xg-g6W_productCard {
    cursor: pointer;
  }

  .xg-g6W_productCard:hover {
    transform: translateY(-5px);
    box-shadow: 0 0 20px 1px #0000001a;
  }
}

@starting-style {
  .xg-g6W_productCard {
    opacity: 0;
    transform: translateY(15px);
  }
}

.GDnPIW_ProductPrices {
  align-items: flex-end;
  gap: .5rem;
  display: inline-flex;
  position: relative;
}

.GDnPIW_ProductPrices__sale {
  opacity: .45;
  font-size: 75%;
  text-decoration: line-through;
}

._76hhCG_clickable {
  cursor: pointer;
  text-decoration: underline;
}

._76hhCG_clickable:hover {
  opacity: .75;
}

.Q0vJjG_Shop {
  grid-template-rows: min-content 1fr;
  width: 100vw;
  height: 100vh;
  display: grid;
}

.Q0vJjG_Shop:has(.Q0vJjG_ShopSubheader) {
  grid-template-rows: min-content min-content 1fr;
}

.Q0vJjG_Shop__content {
  scrollbar-gutter: stable both-edges;
  overflow: auto;
}

.Q0vJjG_ShopSubheader, .Q0vJjG_ShopHeader {
  flex-wrap: wrap;
  gap: 6px;
  display: flex;
}

.RPKJ-q_ProductCard__tags {
  flex-wrap: wrap;
  gap: .25rem;
  margin: 1rem 0;
  display: flex;
}
/*# sourceMappingURL=index.b6f00ec6.css.map */
